import { useLanguage } from "client/contexts/LanguageProvider";
import { CloseIcon } from "components/common/Icons/CloseIcon";
import { languages } from "../Internationalization";
import { Translate } from "components/common/Translate/Translate";
import translatedRoutedSlugs, { type TranslatedRouteSlugs } from "translatedRouteSlugs";
import { useKeyboard } from "client/hooks/utils/useKeyboard";
import { translateFunc } from "client/utils/translateFunc";

export interface LanguageSelectorModalProps {
    isOpen: boolean;
    close: () => void;
    /** should start with "/" */
    url: keyof TranslatedRouteSlugs | "/";
    variables?: Record<string, string>;
}

export const LanguageSelectorModal = ({ isOpen, close, url, variables }: LanguageSelectorModalProps) => {
    const { i18n } = useLanguage();
    useKeyboard("Escape", isOpen, close);

    const getHref = (key: keyof typeof languages) => {
        if (url === "/") return `${key}`;
        const result = `/${key}${"/" + ((translatedRoutedSlugs[url] as any)?.[key] ?? url)}`;
        let finalResult = "";
        result.split("/").forEach((chunk) => {
            if (!chunk) return;
            if (chunk[0] === ":" && variables) {
                finalResult += `/${variables[chunk.substring(1)]}`;
            } else {
                finalResult += `/${chunk}`;
            }
        });
        return finalResult;
    };

    if (!i18n) return null;

    return (
        <div className={`fixed inset-0 z-50 bg-black/25 dark:bg-black/50 ${!isOpen ? "translate-x-full" : ""}`}>
            <div className="flex items-center justify-center h-full" onClick={close}>
                <div
                    className="relative bg-white dark:bg-darkSurface-200 rounded-xl px-16 lg:px-32 py-16 text-black dark:text-white"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        className="absolute right-3 top-3 cursor-pointer"
                        aria-label={translateFunc("common:close-modal")}
                        onClick={close}
                    >
                        <CloseIcon stroke={"black"} strokeWidth={3} height={12} width={12} />
                    </button>
                    <div className="capitalize font-semibold text-[22px] mb-8 text-center">
                        <Translate keyName="select-language" />
                    </div>
                    <div className="grid grid-cols-2 gap-4 text-[15px]">
                        {Object.keys(languages).map((key) => {
                            const isSelected = i18n.language === key;
                            return (
                                <a
                                    key={key}
                                    className={`cursor-pointer flex justify-between items-center border-b border-b-[#E0E0E0] py-2 pl-2 ${
                                        !isSelected && "pr-16"
                                    } ${isSelected && "font-semibold"} text-left`}
                                    href={getHref(key)}
                                >
                                    {languages[key]}
                                    {isSelected && (
                                        <div className="ml-16">
                                            <TickIcon />
                                        </div>
                                    )}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TickIcon = () => (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 0.998386L7.22492 14L0 6.64696L0.980991 5.64858L7.22492 12.0032L19.019 0L20 0.998386Z"
            className="fill-black dark:fill-white"
        />
    </svg>
);
