import { NextRouter } from "next/router";

export const getUrlFromPathname = (pathname: string, router: NextRouter) => {
    if (typeof pathname === "undefined") pathname = "";
    if (!pathname.includes(":") && !pathname.includes("[")) return pathname;
    let startsWithSlash = true;
    if (pathname[0] !== "/") {
        pathname = "/" + pathname;
        startsWithSlash = false;
    }
    const { pathname: _pathname } = router;
    const result = pathname
        .split("/")
        .map((chunk, index) => {
            if (chunk[0] === ":") {
                return router.query[
                    _pathname.split("/")[index].replace(new RegExp("\\[", "g"), "").replace(new RegExp("\\]", "g"), "")
                ];
            }
            return chunk;
        })
        .join("/");

    if (!startsWithSlash) return result.slice(1);
    return result;
};
