export function processAdsOnPage() {
    try {
        // @ts-ignore
        (window.asc = window.asc || { cmd: [] }).cmd.push(function () {
            // @ts-ignore
            window.asc.processAdsOnPage();
            console.log("window.googletag.display()");
            // @ts-ignore
            // window.googletag.display();
        });
    } catch (e) {}
}
