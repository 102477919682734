import { I18n, TFunction, useTranslation } from "next-i18next";
import { createContext, ReactNode, useContext } from "react";

export interface LanguageContextProps {
    files: string[];
    t: TFunction;
    i18n?: I18n;
}

const LanguageContext = createContext<LanguageContextProps>({
    files: [],
    t: () => {},
});

export interface LanguageProviderProps {
    files: LanguageContextProps["files"];
    children: ReactNode;
}

export const LanguageProvider = ({ files, children }: LanguageProviderProps) => {
    const { t, i18n } = useTranslation(files);
    return <LanguageContext.Provider value={{ files, t, i18n }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => useContext(LanguageContext);
