import { LanguageContextProps, useLanguage } from "client/contexts/LanguageProvider";
import { decodeHashCharacters } from "client/utils/decodeHashCharacters";
import { ReactNode } from "react";

export interface TranslateProps {
    keyName: string;
    variables?: Record<any, any>;
    linkRenderer?: (label: string, key: string) => ReactNode;
}

export const Translate = ({ keyName, variables, linkRenderer }: TranslateProps) => {
    const { t, files, i18n } = useLanguage();
    const value = getStringValue(keyName, files, t, i18n);
    const chunks = getChunks(value);

    return (
        <span>
            {chunks.map(({ content, withTag }, index) => {
                const tags = withTag ? getTags(content) : [];
                const processedText = processText(withTag, content, tags, variables);
                let text = tags.includes("key") ? getStringValue(processedText, files, t, i18n) : processedText;
                text = `${tags.includes("quote") ? `"` : ""}${text}${tags.includes("quote") ? `"` : ""}`;
                text = decodeHashCharacters(text);

                const linkTags = tags.filter((tag) => tag.includes("link"));
                if (linkRenderer && linkTags.length > 0) {
                    return <span>{linkTags.map((tag) => linkRenderer(text, tag))}</span>;
                }

                return (
                    <span className={`${tags.includes("bold") && "font-semibold"}`} key={index}>
                        {text}
                    </span>
                );
            })}
        </span>
    );
};

export const processText = (
    withTag: boolean,
    content: string,
    tags: string[],
    variables: TranslateProps["variables"],
) => {
    if (!withTag) {
        return content;
    }
    const innerText = getInnerText(content);

    if (tags.includes("variable")) {
        const variableName = tags.find((tag) => tag.includes("var:"))?.split(":")[1];
        return variables && variableName ? variables[variableName] : innerText;
    }

    return innerText;
};

export const translate = (keyName: string) => {
    const { t, files, i18n } = useLanguage();
    return getChunks(getStringValue(keyName, files, t, i18n))
        .map((chunk) => chunk.content)
        .join("");
};

export const getStringValue = (
    key: string,
    files: LanguageContextProps["files"],
    t: LanguageContextProps["t"],
    i18n: LanguageContextProps["i18n"],
): string => {
    if (i18n && typeof i18n.exists !== "function") {
        if (t(key)) return t(key);
        for (let i = 0; i <= files.length; i++) {
            const value = t(`${files[i]}:${key}`);
            if (value) return value;
        }
    }
    if (i18n?.exists(key)) return t(key);

    let result = ``;

    files.forEach((file) => {
        const value = `${file}:${key}`;
        if (i18n?.exists(value)) result = t(value);
    });

    return result;
};

type Chunk = {
    withTag: boolean;
    content: string;
};

export function getChunks(value: string): Array<Chunk> {
    const chunks: Array<Chunk> = [];
    let latestChunk = ``;

    [...value].forEach((c, index) => {
        if (c !== "[" && c !== "]") {
            latestChunk = `${latestChunk}${c}`;
            if (index >= value.length - 1) {
                chunks.push({
                    content: latestChunk,
                    withTag: latestChunk.includes("[/]"),
                });
            }
            return;
        }

        if (c === "[") {
            if (latestChunk.includes("[")) {
                latestChunk = `${latestChunk}${c}`;
            } else {
                chunks.push({
                    content: latestChunk,
                    withTag: latestChunk.includes("[/]"),
                });
                latestChunk = `${c}`;
            }
        }

        if (c === "]") {
            if (latestChunk.includes("]")) {
                latestChunk = `${latestChunk}${c}`;
                chunks.push({
                    content: latestChunk,
                    withTag: latestChunk.includes("[/]"),
                });
                latestChunk = ``;
            } else {
                latestChunk = `${latestChunk}${c}`;
            }
        }
    });

    return chunks;
}

function getInnerText(value: string): string {
    let result = value;
    result = result.replace(new RegExp("[/]", "g"), "");

    let isTagOpen = false;

    let tempResult = ``;
    [...result].forEach((c) => {
        if (c === "[") {
            isTagOpen = true;
        } else if (c === "]") {
            isTagOpen = false;
        } else if (!isTagOpen) {
            tempResult = `${tempResult}${c}`;
        }
    });
    result = tempResult;

    return result;
}

export function getTags(value: string): Array<string> {
    let startingTagEndPosition = 0;
    for (let i = 0; i < value.length; i++) {
        if (value[i] === "]") {
            startingTagEndPosition = i;
            break;
        }
    }
    return value.substring(1, startingTagEndPosition).split(" ");
}
