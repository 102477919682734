import { useCallback, useEffect } from "react";

export const useKeyboard = (
    key: "Enter" | "Escape" | "Backspace" | "Delete",
    setEventListener: boolean,
    callback: () => void,
) => {
    const listener = useCallback((e: KeyboardEvent) => {
        const tagName = (e.target as HTMLElement).tagName;
        if (key !== e.key || tagName === "INPUT" || tagName === "TEXTAREA") return;
        callback();
    }, []);

    useEffect(() => {
        if (!setEventListener) {
            window.removeEventListener("keyup", listener);
            return;
        }

        window.addEventListener("keyup", listener);
    }, [setEventListener]);

    useEffect(() => {
        return () => {
            window.removeEventListener("keyup", listener);
        };
    }, []);
};
