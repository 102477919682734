export interface ITranslatedRoutedSlugObject {
    [lang: string]: string;
}

const translatedRoutedSlugs: { 
    [key: string]: ITranslatedRoutedSlugObject 
} = {
    "/": {
        en: "",
        bn: "",
        es: "",
        de: "",
        si: "",
        fr: "",
        it: "",
        pt: "",
    },
    "/resize/editor/:taskId": {
        en: "resize/editor/:taskId",
        bn: "আকার-পরিবর্তন/সম্পাদক/:taskId",
        es: "cambiar-el-tamaño/editor/:taskId",
        de: "größe-ändern/editor/:taskId",
        si: "ප්රමාණය-වෙනස්-කරන්න/සංස්කාරක/:taskId",
        fr: "redimensionner/éditeur/:taskId",
        it: "ridimensionare/editore/:taskId",
        pt: "redimensionar/editor/:taskId",
    },
    "/resize/download/:jobId": {
        en: "resize/download/:jobId",
        bn: "আকার-পরিবর্তন/ডাউনলোড/:jobId",
        es: "cambiar-el-tamaño/descargar/:jobId",
        de: "größe-ändern/download/:jobId",
        si: "ප්රමාණය-වෙනස්-කරන්න/බාගත/:jobId",
        fr: "redimensionner/téléchargement/:jobId",
        it: "ridimensionare/scarica/:jobId",
        pt: "redimensionar/download/:jobId",
    },
    "/crop-image": {
        en: "crop-image",
        bn: "ছবি-ক্রপ",
        es: "recortar-imagen",
        de: "bild-zuschneiden",
        si: "රූපය-කප්පාදු",
        fr: "recadrer-l'image",
        it: "ritaglia-l'immagine",
        pt: "cortar-imagem",
    },
    "/crop-image/editor": {
        en: "crop-image/editor",
        bn: "ছবি-ক্রপ/সম্পাদক",
        es: "recortar-imagen/editor",
        de: "bild-zuschneiden/editor",
        si: "රූපය-කප්පාදු/සංස්කාරක",
        fr: "recadrer-l'image/éditeur",
        it: "ritaglia-l'immagine/editore",
        pt: "cortar-imagem/editor",
    },
    "/image-compressor": {
        en: "image-compressor",
        bn: "ইমেজ-কম্প্রেসার",
        es: "compresor-de-imagen",
        de: "bild-kompressor",
        si: "රූප-සම්පීඩකය",
        fr: "compresseur-d'images",
        it: "compressore-di-immagini",
        pt: "compressor-de-imagem",
    },
    "/image-compressor/editor": {
        en: "image-compressor/editor",
        bn: "ইমেজ-কম্প্রেসার/সম্পাদক",
        es: "compresor-de-imagen/editor",
        de: "bild-kompressor/editor",
        si: "රූප-සම්පීඩකය/සංස්කාරක",
        fr: "compresseur-d'images/éditeur",
        it: "compressore-di-immagini/editore",
        pt: "compressor-de-imagem/editor",
    },
    "/compress-jpeg": {
        en: "compress-jpeg",
        bn: "কম্প্রেস-jpeg",
        es: "comprimir-jpeg",
        de: "JPEG-komprimieren",
        si: "සම්පීඩනය-jpeg",
        fr: "compresser-jpeg",
        it: "comprimere-jpeg",
        pt: "compactar-jpeg",
    },

    "/compress-jpeg/editor": {
        en: "compress-jpeg/editor",
        bn: "কম্প্রেস-jpeg/সম্পাদক",
        es: "comprimir-jpeg/editor",
        de: "JPEG-komprimieren/editor",
        si: "සම්පීඩනය-jpeg/සංස්කාරක",
        fr: "compresser-jpeg/éditeur",
        it: "comprimere-jpeg/editore",
        pt: "compactar-jpeg/editor",
    },
    "/png-compressor": {
        en: "png-compressor",
        bn: "কম্প্রেস-png",
        es: "comprimir-png",
        de: "PNG-komprimieren",
        si: "සම්පීඩනය-png",
        fr: "compresser-png",
        it: "comprimere-png",
        pt: "compactar-png",
    },
    "/png-compressor/editor": {
        en: "png-compressor/editor",
        bn: "কম্প্রেস-png/সম্পাদক",
        es: "comprimir-png/editor",
        de: "PNG-komprimieren/editor",
        si: "සම්පීඩනය-png/සංස්කාරක",
        fr: "compresser-png/éditeur",
        it: "comprimere-png/editore",
        pt: "compactar-png/editor",
    },
    "/gif-compressor": {
        en: "gif-compressor",
        bn: "কম্প্রেস-gif",
        es: "comprimir-gif",
        de: "gif-komprimieren",
        si: "සම්පීඩනය-gif",
        fr: "compresser-gif",
        it: "comprimere-gif",
        pt: "compactar-gif",
    },
    "/gif-compressor/editor": {
        en: "gif-compressor/editor",
        bn: "কম্প্রেস-gif/সম্পাদক",
        es: "comprimir-gif/editor",
        de: "gif-komprimieren/editor",
        si: "සම්පීඩනය-gif/සංස්කාරක",
        fr: "compresser-gif/éditeur",
        it: "comprimere-gif/editore",
        pt: "compactar-gif/editor",
    },
    "/bulk-resize": {
        en: "bulk-resize",
        bn: "বাল্ক-রিসাইজ",
        es: "cambio-de-tamaño-masivo",
        de: "bulk-bildgrößenänderung",
        si: "තොග-රූප-ප්‍රමාණය-වෙනස්-කරන්නා",
        fr: "redimensionneur-d'image-en-masse",
        it: "ridimensionatore-di-immagini-in-blocco",
        pt: "redimensionador-de-imagem-em-massa",
    },
    "/bulk-resize/editor": {
        en: "bulk-resize/editor",
        bn: "বাল্ক-রিসাইজ/সম্পাদক",
        es: "cambio-de-tamaño-masivo/editor",
        de: "bulk-bildgrößenänderung/editor",
        si: "තොග-රූප-ප්‍රමාණය-වෙනස්-කරන්නා/සංස්කාරක",
        fr: "redimensionneur-d'image-en-masse/éditeur",
        it: "ridimensionatore-di-immagini-in-blocco/editore",
        pt: "redimensionador-de-imagem-em-massa/editor",
    },
    "/bulk-resize/download/:taskId": {
        en: "bulk-resize/download/:taskId",
        bn: "বাল্ক-রিসাইজ/ডাউনলোড/:taskId",
        es: "cambio-de-tamaño-masivo/descargar/:taskId",
        de: "bulk-bildgrößenänderung/download/:taskId",
        si: "තොග-රූප-ප්‍රමාණය-වෙනස්-කරන්නා/බාගත/:taskId",
        fr: "redimensionneur-d'image-en-masse/téléchargement/:taskId",
        it: "ridimensionatore-di-immagini-in-blocco/scarica/:taskId",
        pt: "redimensionador-de-imagem-em-massa/download/:taskId",
    },
    "/color-picker": {
        en: "color-picker",
        bn: "রঙ-চয়নকারী",
        es: "selector-de-color",
        de: "farbwähler",
        si: "වර්ණ-තෝරා-ගැනීම",
        fr: "pipette-À-couleurs",
        it: "color-picker",
        pt: "seletor-de-cores",
    },
    "/rotate-image": {
        en: "rotate-image",
        bn: "চিত্র-ঘোরান",
        es: "rotar-imagen",
        de: "bild-drehen",
        si: "රූපය-කරකවන්න",
        fr: "faire-pivoter-l'image",
        it: "ruota-immagine",
        pt: "girar-imagem",
    },
    "/flip-image": {
        en: "flip-image",
        bn: "ফ্লিপ-ইমেজ",
        es: "girar-la-imagen",
        de: "bild-kippen",
        si: "රූපය-පෙරළන්න",
        fr: "retourner-l'image",
        it: "capovolgi-immagine",
        pt: "inverter-imagem",
    },
    "/image-enlarger": {
        en: "image-enlarger",
        bn: "ইমেজ-বর্ধক",
        es: "ampliadora-de-imagen",
        de: "bildvergrößerer",
        si: "රූපය-විශාල-කිරීම",
        fr: "agrandisseur-d'images",
        it: "ingranditore-immagine",
        pt: "ampliador-de-imagem",
    },
    "/image-enlarger/editor": {
        en: "image-enlarger/editor",
        bn: "ইমেজ-বর্ধক/সম্পাদক",
        es: "ampliadora-de-imagen/editor",
        de: "bildvergrößerer/editor",
        si: "රූපය-විශාල-කිරීම/සංස්කාරක",
        fr: "agrandisseur-d'images/éditeur",
        it: "ingranditore-immagine/editore",
        pt: "ampliador-de-imagem/editor",
    },
    "/about": {
        en: "about",
        bn: "সম্পর্কিত",
        es: "sobre",
        de: "um",
        si: "පිළිබඳ",
        fr: "sur",
        it: "di",
        pt: "cerca-de",
    },
    "/privacy": {
        en: "privacy",
        bn: "গোপনীয়তা",
        es: "privacidad",
        de: "privatsphäre",
        si: "පෞද්ගලිකත්වය",
        fr: "intimité",
        it: "intimità",
        pt: "privacidade",
    },
    "/terms-of-service": {
        en: "terms-of-service",
        bn: "সেবা-পাবার-শর্ত",
        es: "términos-de-servicio",
        de: "nutzungsbedingungen",
        si: "සේවා-කොන්දේසි",
        fr: "conditions-d'utilisation",
        it: "termini di servizio",
        pt: "termos-de-serviço",
    },
    "/meme-generator": {
        en: "meme-generator",
        bn: "মেম-জেনারেটর",
        es: "generador-de-memes",
        de: "meme-generator",
        si: "meme-generator",
        fr: "générateur-de-mèmes",
        it: "generatore-di-meme",
        pt: "gerador-de-memes",
    },
    "/meme-generator/edit/:slug": {
        en: "meme-generator/edit/:slug",
        bn: "মেম-জেনারেটর/সম্পাদনা/:slug",
        es: "generador-de-memes/editar/:slug",
        de: "meme-generator/bearbeiten/:slug",
        si: "meme-generator/සංස්කරණය/:slug",
        fr: "générateur-de-mèmes/modifier/:slug",
        it: "generatore-di-meme/modificare/:slug",
        pt: "gerador-de-memes/editar/:slug",
    },

    "/changelogs": {
        en: "changelogs",
        bn: "changelogs",
        es: "changelogs",
        de: "changelogs",
        si: "changelogs",
        fr: "changelogs",
        it: "changelogs",
        pt: "changelogs",
    },
} as const;

export type TranslatedRouteSlugs = typeof translatedRoutedSlugs;

export default translatedRoutedSlugs;
