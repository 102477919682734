import { destroyAds } from "client/utils/ads/destroyAds";
import { processAdsOnPage } from "client/utils/ads/processAdsOnPage";
import { useRouter } from "next/router";
import { useEffect } from "react";

const originalUrl = typeof window !== "undefined" ? window.location.pathname : "";
let prevUrl = "";

const routeChangeComplete = () => {
    // @ts-ignore
    processAdsOnPage();
};

let beforeHistoryChangeCount = 0;
const beforeHistoryChange = () => {
    beforeHistoryChangeCount++;
    if (beforeHistoryChangeCount === 1) return;
    destroyAds();
};

export const useAds = () => {
    const router = useRouter();

    const onScriptLoad = () => {
        let count = 0;
        const interval = setInterval(() => {
            count++;
            // @ts-ignore
            if (window.asc) {
                clearInterval(interval);
                processAdsOnPage();
            }

            if (count > 10_000) {
                clearInterval(interval);
            }
        }, 10);
    };

    useEffect(() => {
        if (prevUrl === window.location.href) return;
        prevUrl = window.location.href;

        window.location.pathname === originalUrl && router.events.on("beforeHistoryChange", beforeHistoryChange);

        router.events.on("routeChangeComplete", routeChangeComplete);

        return () => {
            router.events.off("routeChangeComplete", routeChangeComplete);
        };
    }, []);

    return { onScriptLoad };
};
