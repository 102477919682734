import { createContext, type ReactNode, useContext, useEffect, useState } from "react";

export type Theme = "dark" | "light" | "system" | "";
const defaultTheme: Theme = "";
const KEY = "theme";

export interface ThemeContextProps {
    theme?: Theme;
    appliedTheme?: Exclude<Theme, "system">;
    systemTheme?: Exclude<Theme, "system">;
    setTheme?: (theme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [theme, _setTheme] = useState(getTheme());
    const [systemTheme, setSystemTheme] = useState<"dark" | "light">("light");
    const appliedTheme = theme === "system" ? systemTheme : theme;

    const setTheme = (theme: Theme) => {
        if (theme === "") return;
        _setTheme(theme);
        try {
            localStorage.setItem(KEY, theme);
        } catch {}
        changeTheme(theme === "system" ? systemTheme : theme);
    };

    const changeTheme = (theme: "dark" | "light") => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    };

    function onMediaQueryChange(e: MediaQueryListEvent) {
        const systemTheme = e.matches ? "dark" : "light";
        setSystemTheme(systemTheme);
        _setTheme((theme) => {
            if (theme === "system") changeTheme(systemTheme);
            return theme;
        });
    }

    useEffect(() => {
        const theme = getTheme();
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        if (!darkThemeMq) return;
        const systemTheme = darkThemeMq.matches ? "dark" : "light";
        setSystemTheme(systemTheme);
        if (theme === "" || theme === "system") {
            _setTheme("system");
            changeTheme(systemTheme);
        } else {
            _setTheme(theme);
            changeTheme(theme);
        }
        darkThemeMq.addEventListener("change", onMediaQueryChange);
        return () => {
            darkThemeMq.removeEventListener("change", onMediaQueryChange);
        };
    }, []);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                appliedTheme,
                systemTheme,
                setTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);

const getTheme = (): Theme => {
    if (typeof window === "undefined") return "";
    try {
        return (localStorage.getItem(KEY) || "") as Theme;
    } catch (e) {
        return "";
    }
};
