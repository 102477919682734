import type { HTMLAttributes } from "react";
import type { ISVG } from "./UrlIcon";

interface CloseIconProps extends ISVG {
    stroke?: string;
    strokeWidth?: number;
    className?: HTMLAttributes<SVGAElement>["className"];
}

export const CloseIcon = ({ height = 10, width = 10, strokeWidth = 1.5, className }: CloseIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1L16.9993 16.9993"
                className={className || "stroke-black  dark:stroke-white"}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 16.9993L16.9993 1"
                className={className || "stroke-black  dark:stroke-white"}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
