import type { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ArrowIconProps {
    width?: number;
    height?: number;
    stroke?: string;
    className?: HTMLAttributes<SVGElement>["className"];
}

export const ArrowIcon = ({ width = 10, height = 6, className }: ArrowIconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1L4.97647 5L9 1"
                className={twMerge("stroke-black dark:stroke-white", className)}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
